<template>
  <div>
    <v-card>
      <v-card-title>
        Releases
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="releaseCollection"
        :search="search"
        :sort-by="['rl_number']"
        sort-desc="true"
        @click:row="handleClickItem"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th></th>
              <th>
                <v-select
                  v-model="storageNameFilter"
                  :items="[
                    { storage_name: 'Select' },
                    ...storageNameCollection,
                  ]"
                  item-text="storage_name"
                  item-value="storage_name"
                ></v-select>
              </th>
              <th>
                <v-select
                  v-model="nameFilter"
                  :items="[{ owner_name: 'Select' }, ...nameCollection]"
                  item-text="owner_name"
                  item-value="owner_name"
                ></v-select>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn
              v-if="permissionCan('create')"
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="handleNew"
            >
              New release
            </v-btn>

            <v-btn
              v-if="permissionCan('create')"
              color="primary"
              dark
              class="mb-2 ml-2"
              v-bind="attrs"
              v-on="on"
              @click="handleNewProject"
            >
              New project release
            </v-btn>

            <v-spacer></v-spacer>

            <!-- <v-checkbox
              v-model="showAll"
              label="Active departments"
            ></v-checkbox> -->

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/stock/releases/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi mdi-eye </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <ReleaseModalForm
        key="releaselistkey"
        ref="releaselist"
        :releaseModalData="releaseModalData"
        :permissions="permissions"
        @closeModalForm="handleCloseModalForm"
        @saveModalForm="handleSaveModalForm"
        @new="handleNew"
      ></ReleaseModalForm>

      <ProjectReleaseModalForm
        :modalData="modalData"
        :permissions="permissions"
        :storageCollection="storageCollection"
        :projectCollectionActive="projectCollectionActive"
        @closeModalForm="handleCloseProjectModalForm"
        @saveModalForm="handleSaveProjectModalForm"
        @new="handleNew"
      ></ProjectReleaseModalForm>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import ReleaseModalForm, { initialReleaseFormData } from "./ReleaseModalForm";

import ProjectReleaseModalForm from "./ProjectReleaseModalForm";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export const ENDPOINT = "stock/release/";

export const PERMISSION_TO = "stock.release.";

export default {
  name: "releases",
  components: { ReleaseModalForm, ProjectReleaseModalForm },
  data() {
    return {
      showAll: false,
      search: "",
      permissions: [],
      headers: [
        {
          text: "Releases ID",
          value: "rl_number",
        },
        {
          text: "Storage",
          value: "storage_name",
          filter: (value) => {
            if (this.storageNameFilter == "Select") return true;
            return value == this.storageNameFilter;
          },
        },
        {
          text: "User name",
          value: "owner_name",
          filter: (value) => {
            if (this.nameFilter == "Select") return true;
            return value == this.nameFilter;
          },
        },
        {
          text: "Date",
          value: "created_at",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      storageNameFilter: "Select",
      nameFilter: "Select",
      releaseModalData: {
        dialog: false,
        editedId: null,
      },
      modalData: {
        dialog: false,
        editedId: null,
      },

      editedItem: Object.assign({}, initialReleaseFormData),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([
      "releaseCollection",
      "projectCollectionActive",
      "storageCollection",
    ]),

    nameCollection() {
      return this.releaseCollection.map((item) => {
        return item.owner_name;
      });
    },

    storageNameCollection() {
      return this.releaseCollection.map((item) => {
        return item.storage_name;
      });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          // console.log(
          //   "this.$refs.release.handleNew",
          //   this.$refs.release.handleNew
          // );
          this.editItem(id);
          // setTimeout(() => {
          //   if (!this.$refs.release.handleCloseModalForm) {
          //     location.reload();
          //   } else {
          //     this.editItem(id);
          //   }
          // });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchRelease", "fetchStorage", "fetchProjectActive"]),
    editItem(id) {
      this.releaseModalData.editedId = id;
      this.releaseModalData.dialog = true;
    },

    handleNew() {
      this.releaseModalData.editedId = null;
      this.releaseModalData.dialog = true;
    },

    handleNewProject() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    handleCloseModalForm() {
      this.releaseModalData.dialog = false;
      this.releaseModalData.editedId = null;
      this.fetchRelease();

      if (this.$route.name !== "releases")
        this.$router.push({ name: "releases" });
    },

    handleSaveProjectModalForm() {
      this.handleCloseProjectModalForm();
    },

    handleCloseProjectModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedId = null;
      this.fetchRelease();

      if (this.$route.name !== "releases")
        this.$router.push({ name: "releases" });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchRelease();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/stock/releases/${item.id}`);
      }
    },

    closeDelete() {
      // this.editedItem = Object.assign({}, initialFormData);
      this.releaseModalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "stock.release." + action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("release") > -1
        );
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Release" }]);
    this.fetchRelease();
    this.fetchStorage();
    this.fetchProjectActive();

    this.setPermissions();

    // setTimeout(() => {
    if (!this.$refs.releaselist.handleCloseModalForm) {
      location.reload();
    }
    // });
  },
};
</script>
