<template>
  <v-dialog v-model="modalData.dialog" width="1440px" persistent scrollable>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <!--   <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
              <v-col cols="12" sm="3" md="3">
            <v-select
              v-if="statuses.system"
              v-model="formModel.status"
              :items="statuses.companySettings.department"
              label="Status"
              item-text="value"
              item-value="key"
            />
          </v-col> -->
            <v-col cols="6" sm="6" md="6">
              <v-btn
                v-if="
                  (permissionCan('create') && !formModel.id) ||
                  permissionCan('update')
                "
                color="primary"
                dark
                class="mb-2"
                @click="handleSaveModalForm"
              >
                Release
              </v-btn>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <!-- :disabled="
                    (formModel.computedEntities &&
                      formModel.computedEntities.length > 0) ||
                    formModel.id
                  " -->
                <v-select
                  v-model="formModel.storage_id"
                  :items="storageCollection"
                  :rules="requiredRules"
                  label="Select storage"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.project"
                  :items="computedProjectCollection"
                  label="Select project"
                  item-text="pr_number"
                  item-value="id"
                  return-object
                  @change="handleChangeProject"
                ></v-select>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.item"
                  :items="itemCollection"
                  label="Select item"
                  item-text="name"
                  item-value="id"
                  return-object
                  @change="handleChangeItem"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="5" md="5">
                <!-- <pre>
                  {{ computedComponentCollection }}
                </pre> -->

                <v-data-table
                  :headers="headersComponent"
                  v-model="selectedComponent"
                  :items="computedComponentCollection"
                  item-key="id"
                  show-select
                  class="elevation-1"
                >
                </v-data-table>
                <b
                  >Total selected: {{ totalSelected }}
                  {{ computedQuantityUnit }}</b
                >
              </v-col>
              <v-divider class="vertical-divider" vertical> </v-divider>
              <v-col cols="7" sm="7" md="7">
                <!-- <pre>
                  {{ computedItemEntityCollection }}
                </pre> -->
                <v-data-table
                  v-if="formModel.item"
                  :headers="headersItemEntity"
                  :items="computedItemEntityCollection"
                  v-model="selectedItemEntity"
                  item-key="item_entity_id"
                  sort-by="item_entity_id"
                  group-by="quantity"
                  class="elevation-1"
                  show-group-by
                  show-select
                  single-select
                  :items-per-page="-1"
                  :hide-default-footer="true"
                  disable-pagination
                >
                  <template
                    v-slot:group.header="{
                      group,
                      headers,
                      toggle,
                      isOpen,
                      items,
                    }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      Quantity: {{ group }} {{ items[0].quantityUnit }};
                      {{ items[0].pcs }} pcs
                    </td>
                  </template>
                </v-data-table>
                <b>
                  Total quantity: {{ totalQuantity }} {{ computedQuantityUnit }}
                </b>
                <p v-bind:style="{ color: color }">
                  Remainder: {{ remainder }} {{ computedQuantityUnit }}
                </p>

                <v-row
                  v-if="
                    showRemainder &&
                    selectedComponent.length > 0 &&
                    selectedItemEntity.length > 0
                  "
                >
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      v-model="editedItem.quantity"
                      label="Quantity"
                      :rules="maxQuantityRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      v-model="editedItem.serial_number"
                      label="Serial number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <v-checkbox
                      v-model="editedItem.status"
                      label="Receive remainder imediately"
                      value="4"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-btn
                  v-if="
                    (selectedComponent.length > 0 &&
                      selectedItemEntity.length > 0 &&
                      formModel.storage_id &&
                      !formModel.id) ||
                    (permissionCan('update') &&
                      formModel.storage_id &&
                      !formModel.id)
                  "
                  color="primary"
                  dark
                  class="mb-2"
                  @click="handleAddItemToList"
                  :disabled="remainder < 0"
                >
                  Add to release list
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headerDirtyList"
                  :items="computedEntities"
                  group-by="Pcs"
                  show-group-by
                  item-key="item_entity_id"
                  class="elevation-1"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template
                    v-slot:group.header="{ group, headers, toggle, isOpen }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      {{ group }}
                    </td>
                  </template>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Selected entities</v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.name="{ item }">
                    <span> {{ item.nameTranslated }}</span>
                  </template>

                  <template v-slot:item.reference="{ item }">
                    <span> {{ item.reference_number }}</span>
                  </template>

                  <template v-slot:item.quantityUnit="{ item }">
                    <span> {{ item.quantityUnit }}</span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-if="!formModel.id"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <span
                  v-if="messages['entities'] && messages['entities'].length > 0"
                  style="color: red"
                  >{{ messages["entities"] }}
                </span>
                <v-textarea
                  :disabled="formModel.id"
                  v-model="formModel.comment"
                  label="Comment"
                />
                <!-- <pre>
                {{ computedEntities }}
                </pre> -->
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./Releases";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import Swal from "sweetalert2";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = () => ({
  id: null,
  storage_id: null,
  translations: [],
  release_entities: [],
  adjustment_entities: [],
  project: null,
  item: null,
});

export const initialInputData = () => ({
  quantity: null,
  serial_number: "",
});

export default {
  name: "QuantityUnitForm",
  props: [
    "modalData",
    "permissions",
    "storageCollection",
    "projectCollectionActive",
  ],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,
      itemCollection: [],
      formValid: false,
      messages: {},

      nameRules: [
        (v) => !!v || "This field is required",
        (v) => v.length < 255 || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      componentCollection: [],
      itemEntityCollection: [],

      headersComponent: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Component name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
      ],

      headersItemEntity: [
        {
          text: "Entity ID",
          value: "item_entity_id",
          groupable: false,
        },
        { text: "Quant", value: "quantity" },
        { text: "Quantity", value: "fullQuantity", groupable: false },
        // { text: "Quantity unit", value: "quantityUnit" },
        { text: "Reference", value: "reference", groupable: false },
        { text: "Name", value: "name", groupable: false },
        { text: "Storage", value: "storage", groupable: false },
        { text: "Serial No.", value: "serial_number", groupable: false },
      ],

      headerDirtyList: [
        // { text: "Reference", value: "reference" },
        // { text: "Name", value: "name" },
        // { text: "Quantity", value: "quantity" },
        // { text: "Quantity Unit", value: "quantityUnit" },
        // { text: "Piece ID", value: "piece_id" },
        // { text: "Serial NO", value: "serial_number" },
        // { text: "Action", value: "action" },

        { text: "Pcs", value: "Pcs" },
        { text: "ID", value: "item_entity_id", groupable: false },
        { text: "Reference", value: "reference_number", groupable: false },
        { text: "Item name", value: "name", groupable: false },
        { text: "Serial number", value: "serial_number", groupable: false },
        { text: "Status", value: "statusTranslated", groupable: false },
        {
          text: "Original quantity",
          value: "original_quantity",
          groupable: false,
        },
        { text: "Quantity", value: "quantity", groupable: false },
        {
          text: "Quantity unit",
          value: "quantityUnit",
          groupable: false,
        },
        { text: "Storage", value: "storage", groupable: false },
        {
          text: "Remainder serial number",
          value: "remainder_serial_number",
          groupable: false,
        },
        {
          text: "Remainder quantity",
          value: "remainder_quantity",
          groupable: false,
        },

        {
          text: "Remainder status",
          value: "remainderStatusTranslated",
          groupable: false,
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ],

      selectedComponent: [],
      selectedItemEntity: [],
      editedItem: initialInputData(),

      headers: [
        {
          text: "item_entity_id",
          value: "item_entity_id",
        },
        {
          text: "Calstock_transaction_schedule_idories",
          value: "stock_transaction_schedule_id",
        },
        { text: "quantity", value: "quantity" },
      ],

      editedIndex: null,
      dialogDelete: false,
    };
  },
  computed: {
    ...mapGetters(["quantityCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id ? "Edit project release" : "New project release";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    computedProjectCollection() {
      return this.projectCollectionActive;
    },

    computedComponentCollection() {
      this.componentCollection.map((item) => {
        let i = this.getItemById(item.item_id);
        item.name = i.name;
        item.quantity = item.quantity < 0 ? -1 * item.quantity : item.quantity;
        item.quantityUnit = this.$helpers.getTranslated(
          item.quantity_unit_translations
        ).name;
        return item;
      });

      let selectedItemIds = this.formModel.release_entities.map(
        (i) => i.stock_transaction_schedule_id
      );

      if (this.formModel.item) {
        return this.componentCollection.filter(
          (item) =>
            item.item_id == this.formModel.item.id &&
            !selectedItemIds.includes(1 * item.id)
          //  && !selectedItemIds.includes(1 * item.id)
        );
      }

      return this.componentCollection.filter(
        (i) => !selectedItemIds.includes(1 * i.id)
      );
    },

    computedItemEntityCollection() {
      let selectedItemIds = this.formModel.release_entities.map(
        (i) => i.item_entity_id
      );

      let collection = this.itemEntityCollection.filter(
        (i) => !selectedItemIds.includes(i.item_entity_id)
      );

      let pcsArray = [];
      collection.forEach((item) => {
        if (!pcsArray[item.quantity]) {
          pcsArray[item.quantity] = 1;
        } else {
          pcsArray[item.quantity]++;
        }
      });

      collection.map((item) => {
        let i = this.getItemById(item.item_id);
        item.name = i.name;
        item.reference = i.reference;
        item.quantity = item.quantity < 0 ? -1 * item.quantity : item.quantity;
        item.quantityUnit = this.$store.getters.getQuantityUnitByID(
          i.quantity_unit_id
        ).name;
        item.storage = this.storageCollection.find(
          (i) => (i.id = item.storage_id)
        ).name;
        item.fullQuantity = item.quantity + " " + item.quantityUnit;
        item.pcs = pcsArray[item.quantity];
        return item;
      });

      if (this.formModel.item) {
        return collection.filter(
          (item) => item.item_id == this.formModel.item.id
        );
      }
      return collection;
    },

    totalSelected() {
      if (this.selectedComponent.length == 0) return 0;
      return this.selectedComponent.reduce((a, b) => +a + +b.quantity, 0);
    },

    totalQuantity() {
      if (this.selectedItemEntity.length == 0) return 0;
      return this.selectedItemEntity[0].quantity;
    },

    remainder() {
      return (this.totalQuantity - this.totalSelected).toFixed(2);
    },

    showRemainder() {
      return this.remainder != 0;
    },

    computedQuantityUnit() {
      if (this.formModel.item) {
        return this.$store.getters.getQuantityUnitByID(
          this.formModel.item.quantity_unit_id
        ).name;
      }
      return "";
    },

    color() {
      return this.remainder < 0 ? "red" : "black";
    },

    computedDirtyList() {
      let dirty = this.formModel.release_entities.concat(
        this.formModel.adjustment_entities
      );

      dirty.map((item) => {
        let i = this.getItemEntityById(item.item_entity_id);
        item.name = i.name;
        item.reference = i.reference;
        item.quantityUnit = i.quantityUnit;
        return item;
      });

      return dirty;
    },

    computedEntities() {
      this.formModel.release_entities.map((item) => {
        let i = this.getItemEntityById(item.item_entity_id);
        item.nameTranslated = i.name;
        item.reference_number = i.reference;
        item.quantityUnit = i.quantityUnit;

        let remainderItem = this.formModel.adjustment_entities.find(
          (adjustmentItem) => {
            return item.item_entity_id == adjustmentItem.item_entity_id;
          }
        );
        if (remainderItem) {
          item.remainder_serial_number = remainderItem.serial_number;
          item.remainder_quantity = remainderItem.quantity;
          item.remainderStatusTranslated = this.getTranslatedStatus(
            remainderItem.status
          );
        }
        return item;
      });
      // return this.formModel.release_entities;
      return this.calcEntityPcs(this.formModel.release_entities);
    },
  },

  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              setTimeout(() => {
                this.closeAll();
              }, 200);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.resetForm();

          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },

    selectedComponent: function (val, oldVal) {
      if (val.length == 1 && oldVal.length == 0) {
        this.formModel.item = this.itemCollection.find(
          (i) => i.id == val[0].item_id
        );
        this.selectedItemEntity = [];
        setTimeout(() => {
          this.closeAll();
        }, 200);
      } else if (val.length == 0) {
        this.formModel.item = null;
      }
    },

    remainder: function (val) {
      this.editedItem.quantity = val;
    },
  },
  methods: {
    ...mapActions(["fetchQuantityUnit"]),

    calcEntityPcs(entities) {
      if (!entities || typeof entities == "undefined") {
        return [];
      }
      let pcsArray = [];

      entities = entities.map((item) => {
        item.Pcs = this.getItemPcs(item);
        if (pcsArray.indexOf(item.Pcs) === -1) {
          pcsArray.push(item.Pcs);
        }
        return item;
      });
      pcsArray.forEach((pcs) => {
        let sum = 0;
        entities
          .filter((item) => {
            if (item.Pcs == pcs) sum++;
            return item.Pcs == pcs;
          })
          .map((item) => {
            item.Pcs = item.Pcs + " - " + sum + " pcs";
            return item;
          });
      });

      return entities;
    },

    handleChangeProject(project) {
      ApiService.get(`manufacture/project/${project.id}/items`)
        .then(({ data }) => {
          data.items.map((item) => {
            item.name = this.$helpers.getTranslated(item.translations).name;
            return item;
          });

          this.itemCollection = data.items;
          this.componentCollection = data.components.map((item) => {
            item.name = this.getItemById(item.item_id).name;
            return item;
          });
          this.itemEntityCollection = data.itemEntities
            .filter((item) => item.storage_id == this.formModel.storage_id)
            .map((item) => {
              item.name = this.getItemById(item.item_id).name;
              return item;
            });

          setTimeout(() => {
            this.closeAll();
          }, 200);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    getItemById(id) {
      return this.itemCollection.find((i) => i.id == id);
    },

    getItemEntityById(id) {
      return this.itemEntityCollection.find((i) => i.item_entity_id == id);
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },

    handleChangeItem() {
      setTimeout(() => {
        this.closeAll();
      }, 200);
    },

    handleAddItemToList() {
      this.reserveEntityByIds({
        ids: [this.selectedItemEntity[0].item_entity_id],
      })
        .then(() => {
          this.selectedComponent.forEach((item) => {
            this.formModel.release_entities.push({
              item_entity_id: this.selectedItemEntity[0].item_entity_id,
              stock_transaction_schedule_id: item.id,
              status: 6,
              quantity: item.quantity,
            });
          });

          if (this.editedItem.quantity > 0) {
            this.formModel.adjustment_entities.push({
              item_entity_id: this.selectedItemEntity[0].item_entity_id,
              serial_number: this.editedItem.serial_number,
              status: this.editedItem.status ?? 3,
              quantity: this.editedItem.quantity,
            });
          }
          this.selectedItemEntity = [];
          this.selectedComponent = [];
          this.editedItem = initialInputData();
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "The entity is reserved!",
            icon: "error",
          });
        });
    },

    reserveEntityByIds(entityIds) {
      return ApiService.post(ENDPOINT + "itemEntity/reserve", entityIds);
    },

    unReserveEntityByIds(entityIds) {
      ApiService.post(ENDPOINT + "itemEntity/unreserve", entityIds)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    resetForm() {
      this.formModel = Object.assign({}, initialFormData());
      this.fetchQuantityUnit();
      this.editedItem = initialInputData();
      this.componentCollection = [];
      this.itemEntityCollection = [];
      this.selectedComponent = [];
      this.selectedItemEntity = [];
    },

    getTranslatedStatus(id) {
      return this.$t("ADJUSTMENT.status_" + id);
    },

    getItemPcs(item) {
      return (
        " " +
        item.reference_number +
        " " +
        item.nameTranslated +
        " " +
        item.quantity +
        item.quantityUnit
      );
    },
    deleteItem(item) {
      this.editedIndex = this.computedEntities.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let itemToDelete = this.computedEntities[this.editedIndex];

      // let restoredItem = Object.assign(
      //   {},
      //   this.entitiesDeletedArray.find(
      //     (item) => item.item_entity_id == itemToDelete.item_entity_id
      //   )
      // );

      // console.log(restoredItem, itemToDelete);

      // this.entitiesArray.push(restoredItem);

      // this.entitiesArray = this.calcEntityPcs(this.entitiesArray);

      this.unReserveEntityByIds({
        ids: [itemToDelete.item_entity_id],
      });

      this.formModel.release_entities = this.formModel.release_entities.filter(
        (item) => {
          return item.item_entity_id != itemToDelete.item_entity_id;
        }
      );
      this.formModel.adjustment_entities =
        this.formModel.adjustment_entities.filter((item) => {
          return item.item_entity_id != itemToDelete.item_entity_id;
        });
      // this.formModel.entities.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = null;
      });
    },

    handleCloseModalForm() {
      if (this.formModel.release_entities.length > 0)
        this.unReserveEntityByIds({
          ids: this.formModel.release_entities.map((i) => i.item_entity_id),
        });
      this.resetErrorMessages();
      this.$emit("closeModalForm");
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
